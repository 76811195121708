/* Register Page Button CSS */
input.sub-btn:hover {
  background: #3e3936 !important;
  border: 1px solid #3e3936 !important;
}
input.sub-btn {
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 10px 100px;
  border-radius: 5px;
  top: 37px;
  border: 1px solid #95232f;
  background: #95232f !important;
  text-align: center;
  margin: 0 auto;
  margin-top: 20px;
  font-family: 'Nimbus Sans' !important;
}
input#submit1 {
  display: flex;
  justify-content: center;
  color: #fff;
  padding: 10px 100px;
  border-radius: 5px;
  top: 37px;
  border: 1px solid #95232f !important;
  background: #95232f !important;
  text-align: center;
  margin: 0 auto;
  font-family: 'Nimbus Sans' !important;
}
input#submit1:hover {
  background: #3e3936 !important;
  border: 1px solid #3e3936 !important;
}
/* Survey Page Button CSS */
input.submit-btn.survey {
  background: #95232f;
  outline: none;
  color: #fff;
  padding: 7px 15px !important;
  border: 1px solid #95232f;
  border-radius: 5px;
  font-family: 'Nimbus Sans' !important;
}
input.submit-btn.survey:hover {
  background: #3e3936;
  border: 1px solid #3e3936;
}
/* input#submit {
  background: #95232f !important;
  outline: none;
  color: #fff;
  padding: 7px 15px;
  border: 1px solid #95232f !important;
  border-radius: 5px;
  font-family: 'Nimbus Sans' !important;
}

input#submit:hover {
  background: #3e3936!important;
  border: 1px solid#3e3936!important;
} */
input.sub-btn1 {
  background: #95232f !important;
  outline: none;
  color: #fff;
  padding: 7px 15px;
  border: 1px solid #95232f !important;
  border-radius: 5px;
  font-family: 'Nimbus Sans' !important;
}

input.sub-btn1:hover {
  background: #3e3936!important;
  border: 1px solid#3e3936!important;
}
button#btn1 {
  margin-right: 45%;
  color: #fff !important;
  border: 1px solid #95232f;
  border-radius: 10px;
  outline: none !important;
  background-color: #95232f !important;
  font-family: 'Nimbus Sans' !important;
}
button#btn1:hover {
  background: #3e3936!important;
  outline: none !important;
  border: 1px solid #3e3936!important;
}

a.btn.btn-md.btn-primary {
  position: static !important;
  outline: none !important;
  border-radius: 5px !important;
  height: 46px !important;
  padding: 8px !important;
  width: 118px !important;
  background: #95232f !important;
  border: 1px solid #95232f !important;
  color: #fff !important;
  float: right !important;
  font-family: 'Nimbus Sans' !important;
}

a.btn.btn-md.btn-primary:hover {
  background: #3e3936!important;
  border: 1px solid #3e3936!important;
  text-decoration: none !important;
}


input.sub-btn2 {
  background: #95232f !important;
  border: 1px solid #95232f !important;
  outline: none;
  color: #fff;
  padding: 11px 15px;
  border-radius: 5px;
  width: 100%;
  font-family: 'Nimbus Sans' !important;
}
input.sub-btn2:hover {
  background: #3e3936!important;
  border: 1px solid #3e3936!important;
}
a#Thanku_Buttons {
    background-color: #95232f;
    border-color: #95232f;
    color: #fff !important;
    text-decoration: none !important;
  font-family: 'Nimbus Sans' !important;
}
a#Thanku_Buttons:hover {
  background: #3e3936!important;
  border: 1px solid #3e3936!important;
}