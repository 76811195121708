.header_lower {
  font-family: "Nimbus Sans";
  text-align: center;
  font-size: 19px;
  color: #fff;
  margin-bottom: 22px;
  width: 100%;
  /* background-color: #4C4F54; */
  background-color: #3e3936;
  padding: 8px;
  margin: 0 auto;
}
